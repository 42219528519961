import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import PropTypes from "prop-types";
import {Tab, Tabs } from '@mui/material';

import javaPerspectivation from "images/perspectivation_java_2024_01_31_3fps.gif"
import javaRealization from "images/realization_java_2024_12_16_3fps.gif"
import kotlinPerspectivation from "images/perspectivation_kotlin_2024_01_31_3fps.gif"
import kotlinRealization from "images/realization_kotlin_2024_12_16_3fps.gif"
import logo from "images/logo.svg"
import pluginIcon from "images/pluginIcon.svg"
import portrait from "images/portrait.jpg"

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (children)}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default (() => {
	const [languageTabIndex, setLanguageTabIndex] = React.useState(0);

	const handleLanguageTabChange = (event, newValue) => {
		setLanguageTabIndex(newValue);
	};

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Cognitive Editor
			</title>
			<meta name={"description"} content={"A cognitive editor for Java and Kotlin"} />
			<meta property={"og:title"} content={"Cognitive Editor"} />
			<meta property={"og:description"} content={"A cognitive editor for Java and Kotlin\n"} />
		</Helmet>
		<Image
			md-top="300px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			position="absolute"
			right="0px"
			bottom="auto"
			top="130px"
			z-index="-10"
			lg-right="-200px"
			md-width="600px"
		/>
		<Section
			background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)"
			padding="36px 0 36px 0"
			quarkly-title="Header"
			id="home"
			position="static"
			z-index="1000"
		>
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="80%"
				sm-width="50%"
				align-items="center"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override
						slot="Content"
						align-items="right"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
					/>
					<Override slot="Children" justify-content="center" display="flex" align-items="center" />
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
					>
						<Link
							href="#"
							font="normal 600 18px/1.5 --fontFamily-googleInter"
							text-decoration-line="initial"
							color="--white"
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
							white-space="nowrap"
						>
							{/*<Image
								src={logo}
								height="100%"
								width="auto"
								style={{ paddingTop: '5pt', paddingRight: '10pt' }}
							/>*/}
							Home
						</Link>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#features"
							font="--base"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 20px 0px 20px"
							opacity="0.8"
						>
							Features
						</Link>
						<Link
							color="--grey"
							margin="0px 20px 0px 20px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							href="#public-beta"
							font="--base"
							text-decoration-line="initial"
							opacity="0.8"
							hover-opacity="1"
							md-color="--indigo"
							white-space="nowrap"
						>
							Public Beta
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--grey"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#about"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							About
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="0px 0 20px 0" quarkly-title="HeroBlock" md-padding="0px 0 12px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-z-index="7"
			/>
			<Image
				object-fit="contain"
				md-height="108px"
				lg-height="120px"
				height="160px"
				md-padding="0px 12px 0px 0px"
				lg-padding="0px 16px 0px 0px"
				padding="0px 20px 0px 0px"
				src={logo}
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 0px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="100%"
				padding="0px 0px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					color="--grey"
					lg-text-align="left"
					font="--base"
					lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
				>
					IntelliJ plug-in for Java and Kotlin
				</Text>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
					white-space="nowrap"
				>
					Cognitive Editor
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
					Fluent coding and less redundancy with fewer clicks and no prompts
				</Text>
			</Box>
		</Section>
		<Section
			sm-padding="30px 0 0px 0"
			md-padding="30px 0 10px 0"
			lg-padding="60px 0 20px 0"
			id="features"
			quarkly-title="Fluent references"
		>
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="0px 0px 38px 0px"
				font="--headline2"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				Fluent coding
			</Text>
			<Box
				display="flex"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				md-border-width="0px 0px 2px 0px"
				margin="0px 0px 20px 0px"
				width="100%"
				border-style="solid"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				border-radius="10px"
				flex-wrap="wrap"
				min-height="284px"
				md-border-radius="0px"
				background-color="white"
			>
				<Box
					padding="32px 7% 32px 32px"
					width="50%"
					display="flex"
					position="relative"
					md-padding="32px 30px 32px 0px"
					flex-direction="column"
					justify-content="space-between"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
					md-width="100%"
				>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="100%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						Stay focused
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						The cognitive editor analyzes your codebase and
						fixes it based on the identifiers used: names are
						completed, details added.
						Focus on your task, don't prompt. The editor won't
						try to predict rightwards.
						<br />
						<br />
						(Example based on{" "}
						<Link
							href="https://github.com/spring-guides/tut-spring-boot-kotlin"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							tut-spring-boot-kotlin
						</Link>
						)
					</Text>
				</Box>
				<Box
					md-order="-1"
					width="50%"
					display="flex"
					flex-direction="row"
					lg-width="50%"
					md-width="100%"
					height="300+48px"
				>
					<Box>
						<Tabs value={languageTabIndex} onChange={handleLanguageTabChange} aria-label="fluent reference examples">
							<Tab label="Java" {...a11yProps(0)} />
							<Tab label="Kotlin" {...a11yProps(1)} />
						</Tabs>
						<CustomTabPanel value={languageTabIndex} index={0}>
							<Image
								object-fit="cover"
								object-position="left"
								width="100%"
								border-radius="0px 0px 10px 0px"
								md-margin="0px 0px 0px 0px"
								margin="0px 5px -4px 0px"
								src={javaRealization}
								height="300px"
							/>
						</CustomTabPanel>
						<CustomTabPanel value={languageTabIndex} index={1}>
							<Image
								object-fit="cover"
								object-position="left"
								width="100%"
								border-radius="0px 0px 10px 0px"
								md-margin="0px 0px 0px 0px"
								margin="0px 5px -4px 0px"
								src={kotlinRealization}
								height="300px"
							/>
						</CustomTabPanel>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			sm-padding="30px 0 0px 0"
			md-padding="30px 0 10px 0"
			lg-padding="30px 0 20px 0"
			id="features2"
			quarkly-title="Less redundancy"
		>
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="0px 0px 38px 0px"
				font="--headline2"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				Reduced redundancy
			</Text>
			<Box
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 10px 0px"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				padding="0px 0px 0px 0px"
				border-style="solid"
				display="flex"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				md-border-color="rgba(66, 82, 207, 0.1)"
			>
				<Box display="flex" lg-width="50%" md-width="100%" width="50%">
					<Box>
						<Tabs value={languageTabIndex} onChange={handleLanguageTabChange} aria-label="reduced redundancy examples">
							<Tab label="Java" {...a11yProps(0)} />
							<Tab label="Kotlin" {...a11yProps(1)} />
						</Tabs>
						<CustomTabPanel value={languageTabIndex} index={0}>
							<Image
								object-fit="cover"
								width="100%"
								border-radius="0px 0px 0px 10px"
								md-margin="0px 0px 0px 0px"
								margin="0px 5px -4px 0px"
								src={javaPerspectivation}
								height="350px"
							/>
						</CustomTabPanel>
						<CustomTabPanel value={languageTabIndex} index={1}>
							<Image
								object-fit="cover"
								width="100%"
								border-radius="0px 0px 0px 10px"
								md-margin="0px 0px 0px 0px"
								margin="0px 5px -4px 0px"
								src={kotlinPerspectivation}
								height="350px"
							/>
						</CustomTabPanel>
					</Box>
				</Box>
				<Box
					padding="32px 7% 32px 62px"
					width="50%"
					justify-content="space-between"
					md-width="100%"
					md-padding="32px 30px 32px 0px"
					display="flex"
					flex-direction="column"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
				>
					<Text color="--indigo" lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif" margin="0px 0px 8px 0px" font="--headline4">
						Fold away redundant declarations
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						If the left-hand side of a declaration merely repeats information from the right-hand side, it is collapsed. Click on the fold to expand it.{" "}
						<br />
						<br />
						Can be disabled in{" "}
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Editor
						</Em>
						{" "}>{" "}
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Cognitive
						</Em>
						{" "}settings.
						<br />
						<br />
						(Example based on{" "}
						<Link href="https://github.com/spring-guides/tut-spring-boot-kotlin">
							tut-spring-boot-kotlin
						</Link>
						)
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			sm-padding="10px 0 0px 0"
			md-padding="20px 0 10px 0"
			lg-padding="60px 0 20px 0"
			id="public-beta"
			quarkly-title="Public beta"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				justify-content="center"
				align-items="flex-start"
				flex-direction="column"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				width="70%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
			>
				<Text
					sm-margin="0px 0px 18px 0px"
					md-margin="0px 0px 28px 0px"
					lg-margin="0px 0px 38px 0px"
					font="--headline2"
					color="--indigo"
					md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					text-align="left"
				>
					Public Beta
				</Text>
				<Box
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					display="flex"
					flex-wrap="wrap"
					width="100%"
				>
					<Box display="flex" lg-width="45%" md-width="100%" width="45%">
						<Text
							margin="0px 0px 0px 0px"
							display="inline"
							position="static"
							font="--textBase"
							color="--darkL1"
						>
							<Link href="https://plugins.jetbrains.com/plugin/26519-cognitive-editor">
								<Image src={pluginIcon}
									   height="25px"
									   align="top"
									   padding-right="5px"
								/>
								Install from JetBrains Marketplace
							</Link>
							<br/>
							<br/>
							See{" "}
							<Link href="https://monochromata.de/doc/">documentation</Link> for installation and getting
							started. Please report feedback and problems as{" "}
							<Link href="https://monochromata-km5mf.app.codey.ch/monochromata/cognitive-editor/issues">issues</Link>.
						</Text>
					</Box>
					<Box
						padding="0px 0% 0px 32px"
						width="55%"
						md-width="100%"
						md-padding="0px 0px 0px 0px"
						display="flex"
						flex-direction="column"
						position="relative"
						lg-padding="0px 0px 0px 32px"
						lg-width="55%"
					>
						<Text
							margin="0px 0px 0px 0px"
							display="inline"
							position="static"
							font="--textBase"
							color="--darkL1"
						>
							For additional languages, IDEs and editors as well as other integrations
							please open a{" "}
							<Link href="https://monochromata-km5mf.app.codey.ch/monochromata/cognitive-editor/issues">issue</Link>{" "}
							or get in touch on{" "}
							<Link href="https://www.linkedin.com/in/sebastian-lohmeier-a998b71b7">LinkedIn</Link>.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			sm-padding="10px 0 10px 0"
			md-padding="20px 0 20px 0"
			lg-padding="60px 0 30px 0"
			id="about"
			quarkly-title="About">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				justify-content="center"
				align-items="flex-start"
				flex-direction="column"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				width="70%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
			>
				<Text
					sm-margin="0px 0px 18px 0px"
					md-margin="0px 0px 28px 0px"
					lg-margin="0px 0px 38px 0px"
					font="--headline2"
					color="--indigo"
					md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					text-align="left"
				>
					About
				</Text>
				<Box min-width="100px" display="flex" flex-direction="row">
					<Image src={portrait}
						   sm-height="50px"
						   sm-min-width="50px"
						   md-height="80px"
						   md-min-width="80px"
						   lg-height="150px"
						   lg-min-width="150px"
						   height="200px"
						   min-width="200px"
						   object-position="top left"
						   style={{
								marginRight: '1em',
								borderRadius: '2%'
					       }}
					/>
					<Text margin="0px 0px 0px 0px"
						  display="inline"
						  position="static"
						  font="--textBase"
						  color="--darkL1">
						monochromata is the company founded by Sebastian Lohmeier to sell the Cognitive Editor
						to finance the development of the editor and the research behind it.<br/>
						<br/>
						The editor is also the subject of the{" "}
						<Link href="https://www.mi.fu-berlin.de/w/Main/SebastianLohmeier#Research" color="#000000">
							PhD project
						</Link>
						{" "}of Sebastian Lohmeier at FU Berlin. The PhD is about
						developing and evaluating the editor. Evaluation involves
						users and is also carried out on a corpus of 36 million lines
						of Java and Kotlin code.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-indigo" padding="20px 0 40px 0" md-padding="40px 0 40px 0" quarkly-title="Footer">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="25%"
				display="flex"
				md-width="100%"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-align-items="center"
			>
				<Link
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="#home"
					color="--white"
					opacity=".8"
				>
					Home
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://monochromata.de/doc/"
					color="--white"
				>
					Documentation
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://mastodon.business/@monochromata"
					color="--white"
				>
					News
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://monochromata-km5mf.app.codey.ch/monochromata/cognitive-editor/issues"
				>
					Issues
				</Link>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="25%"
				display="flex"
				md-width="100%"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-align-items="center"
			>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/privacy"
				>
					Privacy policy
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/eula"
				>
					EULA
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/impressum"
				>
					Impressum
				</Link>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});